<template>
  <div class="article">
    <div class="container">
      <div class="article-list">
        <el-card
          v-for="(item, idx) in datas"
          :key="idx"
          shadow="never"
          class="article-item"
          @click="onArticleTo(item)"
        >
          <template #header>
            <div class="article-item-hd">
              <div class="article-item-time">{{ item.created_at }}</div>
            </div>
          </template>
          <div class="article-item-content">
            <div class="article-item-info">
              <div class="article-item-title">{{ item.title }}</div>
              <div>{{ item.intro }}</div>
            </div>
            <div v-if="item.img" class="article-item-img">
              <img :src="item.img" alt="" />
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { articleList } from '@/api/app.js'
import { articleTo } from '@/utils'
const datas = ref([])
const sourceData = ref({})
const init = (page = 1) => {
  articleList({ page, pageSzie: 20, sort_id: 4 }).then((res) => {
    const { error, data } = res
    if (error == 0) {
      const { items } = data
      datas.value = items
      sourceData.value = data
    }
  })
}
init()
const onArticleTo = (item) => {
  articleTo(item)
}
</script>

<style lang="scss" scoped>
.container {
  padding-top: 30px;
  padding-bottom: 30px;
}
.article {
  &-list {
    min-height: 800px;
  }
  &-item {
    border-radius: 0;
    margin-bottom: 1px;
    border-left: none;
    border-right: none;
    border-top: none;
    cursor: pointer;
    &:hover {
      background-color: rgba(#999, 0.1);
    }
    :deep(.el-card__header) {
      border-bottom: none;
      padding-bottom: 0;
    }
    &-hd {
      font-size: 26px;
      display: flex;
      justify-content: space-between;
    }
    &-time {
      font-size: 20px;
    }
    &-content {
      display: flex;
      justify-content: space-between;
    }
    &-info {
      flex: 1;
      width: 75%;
      color: #999;
    }
    &-title {
      font-size: 26px;
      margin-bottom: 10px;
      color: #333;
    }
    &-img {
      width: 160px;
      height: 100px;
      margin-top: -40px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
